import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import moment from "moment";

import { useParams } from "react-router-dom";
import { Box, Grid, Button, IconButton } from "@material-ui/core";
import {
  getSlots,
  getSlotsByRoles,
  updateAppointment,
} from "../Services/Services";
import { BlockUI } from "../Backdrop/Backdrop";
import TextField from "@material-ui/core/TextField";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ConfirmDialog from "../Dashboard/ConfirmDialogue";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  gridBorderBott: {
    borderBottomColor: "black",
    borderBottomWidth: "1px",
    borderBottomStyle: "dotted",
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  dategrid: {
    margin: theme.spacing(1),
  },
}));



export default function CustomerAppointment() {
  const classes = useStyles();
  let { org, date, appointmentId } = useParams();
  const [slots, setSlots] = React.useState([]);
  const [role, setRole] = React.useState("");
  const [customer, setCustomer] = React.useState();
  const [blockUi, setBlockUI] = React.useState(false);
  const [appointmentDate, setAppointmentDate] = React.useState();
  const [showLeft, setShowLeft] = React.useState(false);
  const [showRight, setShowRight] = React.useState(false);
  const [employee, setEmployee] = React.useState("");
  const [bookingFlag, setBookingFlag] = React.useState(false);
  const [startTime, setStartTime] = React.useState("");
  const [errorMessage,setErrorMessage] = React.useState("");

  const addDate = (days) => {
    setAppointmentDate(
      moment(appointmentDate).add(days, "days").format("YYYY-MM-DD")
    );
  };

  const compareDays = () => {
    var today = moment(new Date());
    var hours = today.diff(appointmentDate, "hours");
    console.log(hours);
    if (hours < 24 * -7) setShowRight(true);
    else setShowRight(false);

    if (hours > 0) setShowLeft(true);
    else setShowLeft(false);

    if(hours > 24){
      setErrorMessage("Sorry your Appointment was in the Past. You can move your appointments forward.");
    }
    else
    setErrorMessage("");
    
  };

  const getAppointmentSlots = () => {
    if (appointmentDate)
      getSlotsByRoles(org, appointmentDate, role).then((slots) => {
        setSlots(slots);
        setBlockUI(false);
      });
  };
  const getClientSlots = () => {
    getSlots(org, date, appointmentId).then((data) => {
      if(!data.employee)
      {
        setErrorMessage("Something went wrong!!");
        setBlockUI(false);
        return
      }
      setRole(data.role);
      setCustomer(data.customer);
      setEmployee(data.employee);
      setAppointmentDate(date);

      getAppointmentSlots();
      setBlockUI(false);
    }).catch((err)=>{
      console.log(err);
    });
  };

  const bookAppointment = (startTime) => {
    setBlockUI(true);
    var details = {
      role: role,
      employee: employee,
      customer: {
        name: customer.name,
        email: customer.email,
      },
      appointmentDate: appointmentDate,
      startTime: startTime,
    };
    updateAppointment(org, date, appointmentId, details)
      .then(() => {
        setBlockUI(false);
        setBookingFlag(false);
        setBookingFlag("Your Booking is confirmed. Please close the window.")
      })
      .catch((err) => {
        alert(err);
        setBlockUI(false);
      });
  };

  useEffect(() => {
    setBlockUI(true);

    if (slots.length === 0) {
      getClientSlots();
    } else getAppointmentSlots();
    compareDays();
  }, [appointmentDate]);

  return (
    <div className={classes.root}>
      {blockUi ? <BlockUI /> : ""}
    
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Confirm your Appointment with {org}
          </Typography>
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        
        <Box>
          <Grid container justifyContent="space-around" spacing={0}>
            <Grid item xs={6}>
              <Typography variant="caption">Booking For:</Typography>
            </Grid>
            <Grid item xs={6}>
              {customer && (
                <TextField
                  id="name"
                  fullWidth
                  disabled
                  type="text"
                  value={customer.name}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Booking Email Address:</Typography>
            </Grid>
            <Grid item xs={6}>
              {customer && (
                <TextField
                  id="email"
                  fullWidth
                  disabled
                  type="text"
                  value={customer.email}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Booking Service:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="email"
                fullWidth
                disabled
                type="text"
                value={role}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid
              container
              justifyContent="center"
              className={classes.dategrid}
            >
              <Grid item xs={3}>
                <IconButton
                  style={{ float: "left" }}
                  disabled={showLeft}
                  onClick={() => addDate(-1)}
                >
                  <ChevronLeftIcon
                    fontSize="large"
                    color={showLeft ? "disabled" : "primary"}
                  />
                </IconButton>
              </Grid>

              <Grid item xs={6}>
               
                { appointmentDate && (
                  <TextField
                    id="date"
                    fullWidth
                    disabled
                    value={appointmentDate}
                    className={classes.textField}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <IconButton
                  style={{ float: "right" }}
                  disabled={showRight}
                  onClick={() => addDate(1)}
                >
                  <ChevronRightIcon
                    fontSize="large"
                    color={showRight ? "disabled" : "primary"}
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={1}>

                {
                  errorMessage ? <Typography variant="h6" color="error">{errorMessage}</Typography>:""
                }
              {!errorMessage &&  slots &&
                slots.map((slot) => {
                  return (
                    <Grid
                      key={slot.slot}
                      container
                      className={classes.gridBorderBott}
                      spacing={0}
                    >
                      <Grid item xs={4} className={classes.button}>
                        <Typography align="center" variant="h6">
                          {slot.slot}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} className={classes.button}>
                        {" "}
                        <Button
                          fullWidth
                          variant="contained"
                          disabled={slot.booked}
                          onClick={() => {
                            setBookingFlag(true);
                            setStartTime(slot.slot)
                          }}
                          color="primary"
                        >
                          {slot.booked ? "Unavailable" : "Book"}
                        </Button>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          <ConfirmDialog
            open={bookingFlag}
            id={startTime}
            handleClose={() => setBookingFlag(false)}
            deleteMethod={bookAppointment}
            message={
              "Are you confirming your Booking on " +
              appointmentDate +
              " at " +
              startTime +
              " ?"
            }
          />
        </Box>
      </main>
    </div>
  );
}
