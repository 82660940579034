import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./Login/Login";
import { AuthContextProvider } from "./Contexts/AuthContext";
import Dashboard from "./Dashboard/Dashboard";
import CustomerAppointment from "./CustomerAppointment/CustomerAppointment";

import { ThemeProvider } from "@material-ui/core/styles";
import { Theme } from "./Theme/Theme";

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/reset">
            Password Reset
          </Route>
          <Route exact path="/dashboard">
            <ThemeProvider theme={Theme}>
              <Dashboard />
            </ThemeProvider>
          </Route>
          <Route exact path="/customerappointments/:org/:date/:appointmentId">
            <ThemeProvider theme={Theme}>
              <CustomerAppointment />
            </ThemeProvider>
          </Route>
          <Route exact path="/">
            <ThemeProvider theme={Theme}>
              <Dashboard />
            </ThemeProvider>
          </Route>
        </Switch>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
