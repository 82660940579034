// import configData from "./config.json";
import fetchIntercept from 'fetch-intercept';
import Cookie from "universal-cookie";

fetchIntercept.register({
    
    
    
    response: function (response) {
        // Modify the reponse object
        
        
        if(response.status===403){
            let cookie = new Cookie();
            cookie.remove("access_token");
            window.location.replace("/login");
            
        }
        return response;
    },

    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});

export  const login = async (auth) => {
    
    try {
        const resp = await fetch("/api/user/login", {
            method: "GET",
            headers: {
                "Authorization": "Basic " + auth,
                "accepts": "application/json"
            }
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    } catch (error) {
        console.log(error.message);
        throw error;
    }

} 

export  const register = (props) => {
    
} 

export const getRoles = async (token,org) => {
    try{
        const resp = await fetch("/api/organisation/"+encodeURI(org)+"/roles/", {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json"
            },
            
        },{cache: "no-cache"});
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
}

export const getEmployees = async (token,org,role) => {

    try{
        const resp = await fetch("/api/organisation/"+encodeURI(org)+"/"+encodeURI(role)+"/employees/", {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json"
            },
            
        },{cache: "no-cache"});
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const deleteRole = async (token,org,role) => {
    try{
        const resp = await fetch("/api/organisation/"+encodeURI(org)+"/"+encodeURI(role), {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            }
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
}

export const deleteEmployee = async (token,org,role,email) => {
    try{
        const resp = await fetch("/api/organisation/"+encodeURI(org)+"/"+encodeURI(role)+"/"+encodeURI(email), {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            }
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
}



export const createEmployees = async (token,org,role,employees) => {
    try{
        var data = {
            "employees" : employees
        }
        const resp = await fetch("/api/organisation/" + encodeURI(org) + "/" + encodeURI(role) + "/employees/create", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const createRoles = async (token,org,roles) => {
    try{
        var data = {
            "roles" : roles
        }
        const resp = await fetch("/api/organisation/"+encodeURI(org)+"/roles/create", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}
export const getOrganisations = async (token) => {

    try {
        const resp = await fetch("/api/organisation/", {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json"
            }
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    } catch (error) {
        console.log(error.message);
        throw error;
    }

}

export const createSettings = async (token,org,settings) => {
    try{
        var data = {
            "settings" : settings
        }
        const resp = await fetch("/api/organisation/" + encodeURI(org) + "/settings", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const createAppointment = async (token,org,details) => {
    try{
        var data = {
            "details" : details
        }
        const resp = await fetch("/api/appointment/" + encodeURI(org) + "/create", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const updateAppointment = async (org,date,id,details) => {
    try{
        var data = {
            "details" : details
        }
        const resp = await fetch("/api/customerappointments/" + encodeURI(org) + "/"+encodeURI(date) + "/" + encodeURI(id) + "/update", {
            method: "POST",
            headers: {
                "accepts": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const getSettings = async (token,org) => {
    try{
        
        const resp = await fetch("/api/organisation/" + encodeURI(org) + "/settings", {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            }
            
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const getAppointments = async (token,org,date) => {
    try{
        
        const resp = await fetch("/api/appointment/" + encodeURI(org) + "/"+encodeURI(date), {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            }
            
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const deleteAppointment = async (token,org,date,id) => {
    try{
        
        const resp = await fetch("/api/appointment/" + encodeURI(org) + "/delete/"+encodeURI(date)+"/"+encodeURI(id), {
            method: "DELETE",
            headers: {
                "Authorization": "Bearer " + token,
                "accepts": "application/json",
                "Content-Type": "application/json"
            }
            
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const getSlots = async (org,date,id) => {
    try{
        
        const resp = await fetch("/api/customerappointments/" + encodeURI(org) + "/"+encodeURI(date)+"/"+encodeURI(id), {
            method: "GET",
            headers: {
                "accepts": "application/json",
                "Content-Type": "application/json"
            }
            
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}

export const getSlotsByRoles = async (org,date,role) => {
    try{
        
        const resp = await fetch("/api/customerappointments/" + encodeURI(org) + "/"+encodeURI(date)+"/role/"+encodeURI(role), {
            method: "GET",
            headers: {
                "accepts": "application/json",
                "Content-Type": "application/json"
            }
            
        });
        const resp_1 = await resp.json();
        if (resp_1.error)
            throw (resp_1.error);
        return resp_1;
    }
    catch(error){
        console.log(error.message);
        throw error;
    }
    
}