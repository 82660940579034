import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  getEmployees,
  deleteEmployee,
  createEmployees
} from "../../Services/Services";
import { useAuth } from "../../Contexts/AuthContext";
import { BlockUI } from "../../Backdrop/Backdrop";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Button, IconButton, Paper } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ConfirmDialog from "../ConfirmDialogue";
import Collapse from '@material-ui/core/Collapse';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  button: {
    margin:"10px"
  },
  formControl: {
    width: "100%",
  },
}));

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default function Employee(props) {
  const classes = useStyles();
  
  const { userObj, orgObj } = useAuth();
  const { rolename } = props;

  const [blockUi, setBlockUi] = React.useState(false);

  const [providerName, setProviderName] = React.useState("");
  const [providerEmail, setProviderEmail] = React.useState("");

  const [employees, setEmployees] = React.useState([]);
  const [email,setEmail] = React.useState();
  const [deleteFlg, setDeleteFlag] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  
  const [inFlag, setInFlag] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const addEmployee = () => {
    setBlockUi(true);
    let newEmployee = [];
    newEmployee.push({
      name: providerName,
      email_address: providerEmail,
    });
    console.log(newEmployee);
    setProviderName("");
    setProviderEmail("");
    createEmployees(userObj.token, orgObj.id,rolename, newEmployee).then((success) => {
      formEmployees();
      setBlockUi(false);
      setOpen(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteEmp = (email) => {
    setBlockUi(true);

    deleteEmployee(userObj.token, orgObj.id, rolename,email).then((success) => {
      formEmployees();
      setDeleteFlag(false);
      setBlockUi(false);
    });
  };

  const formEmployees =() => {
    getEmployees(userObj.token, orgObj.id, rolename)
    .then((employees) => {
      console.log(employees);
      setEmployees(employees);
      
      setInFlag(false);
    }).then(()=>{
        console.log("Avoiding memory leak");
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const onDelete = (email) => {
    setDeleteFlag(true);
    setEmail(email);
  };

  

  
  

  

  useEffect(() => {
    if (userObj !== undefined) {
        formEmployees();
    }
  }, [userObj]);

  return (
    <React.Fragment>
        {
            blockUi ? <BlockUI />:""
        }
    <Paper className={classes.root}>
      
      <List>

        {employees.length > 0 ?
        (employees &&
          employees.map((employee) => {
            return (
                <React.Fragment key={"fg" + employee.email_address}>
                  <Collapse in={!inFlag} timeout="auto" unmountOnExit>
                    <ListItem
                      key={employee.email_address}
                      dense={true}
                    >
                        
                      <ListItemText
                        primary={employee.email_address}
                        secondary={employee.name}
                      />
                      <ListItemIcon ><IconButton onClick={()=>onDelete(employee.email_address)}>
            <DeleteForeverIcon  fontSize="large" color="primary" /></IconButton></ListItemIcon>
                    </ListItem>
                  
                  <ConfirmDialog key={"cd"+employee.email_address} open={employee.email_address === email && deleteFlg} id={employee.email_address} handleClose={setEmail} deleteMethod={deleteEmp} message={"Deleting Employee will also delete all Appointments currently associated with them. Do you want to continue?"}/>
                  </Collapse>
                </React.Fragment>
              );
          })
          ):<div className={classes.button}>Start adding your providers</div>}
      </List>
      <Button variant="contained" color="secondary"  className={classes.button} onClick={handleClickOpen}><AddIcon /></Button>

      
      
    </Paper>
    <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">Add Trainers</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="providerName"> Name</InputLabel>
          <Input
            fullWidth
            id="providerName"
            value={providerName}
            onChange={(e) => setProviderName(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="providerEmail">Email Address</InputLabel>
          <Input
          type="email"
            fullWidth
            id="providerEmail"
            value={providerEmail}
            onChange={(e) => setProviderEmail(e.target.value)}
          />
        </FormControl>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      <Button
        disabled={!validateEmail(providerEmail) || !providerName}
        onClick={addEmployee}
        color="primary"
      >
        Save
      </Button>
    </DialogActions>
  </Dialog>
  </React.Fragment>
  );
}
