import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, FormControl, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useAuth } from "../Contexts/AuthContext";
import { BlockUI } from "../Backdrop/Backdrop";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import BookingTable from "./BookingTable";

import {
  createAppointment,
  getRoles,
  getEmployees,
  getAppointments,
} from "../Services/Services";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  textField: {
    marginTop: "10px",
    width: "100%",
  },
  button: {
    marginTop: "10px",
    width: "100%",
  },
}));

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function Bookings() {
  const classes = useStyles();
  const [bookings, setBookings] = useState([]);

  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("");
  const [trainerLabel, setTrainerLabel] = useState("Trainer");
  const { userObj, orgObj } = useAuth();
  
  const [customeremail, setCustomerEmail] = useState("");
  const [customername, setCustomerName] = useState("");
  const [blockUi, setBlockUI] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const getYMD =()=> {

    let dtS = new Date();
    let dd = dtS.getDate();
    let mm = dtS.getMonth() + 1;
    let yy = dtS.getFullYear();
    
    return yy + "-" + (mm > 9 ? mm : "0" + mm) + "-"+ (dd > 9 ? dd :"0"+dd);

  }
  const [appointmentDate, setAppointmentDate] = useState(getYMD());

  

  const fab = {
    color: "primary",
    className: classes.fab,
    icon: <AddIcon />,
    label: "Add",
  };

  const getRolesOrg = () => {
    getRoles(userObj.token, orgObj.id).then((res) => {
      setProviders(res);
    });
  };

  const getEmployeesRole = (role) => {
    setProvider(role);
    getEmployees(userObj.token, orgObj.id, role).then((res) => {
      setEmployees(res);
    });
  };

  const cancelAppointment = () => {
    setProvider("");
      setEmployee("")
      setCustomerEmail("");
      setCustomerName("");
      setOpen(false);
  }
  const saveAppointment = () => {
    setBlockUI(true);
    console.log(!provider || !employee || !customername || !validateEmail(customeremail));
    if(!provider || !employee || !customername || !validateEmail(customeremail))
    {
      alert("Please enter all required details.")
      return;
    }
    var details = {
      role: provider,
      employee: employee,
      customer: {
        name: customername,
        email: customeremail,
      },
      appointmentDate: appointmentDate,
      startTime: "",
    };
    createAppointment(userObj.token, orgObj.id, details).then(()=>{
      setBlockUI(false);
      getApps(appointmentDate);
      
      cancelAppointment();

    }).catch((err)=>{
      setBlockUI(false);
      console.log(err);
    });
  };

  const getApps = (date) => {
    console.log(date);
    setBlockUI(true);
    setAppointmentDate(date);
    getAppointments(userObj.token, orgObj.id, date).then((resp) => {
        console.log(resp)
        setBookings(resp);
        setBlockUI(false);
    });
  };
  useEffect(() => {
    getRolesOrg();
    getApps(appointmentDate);
  }, []);

  return (
    <div className={classes.root}>
      {blockUi ? <BlockUI /> : ""}
      <Typography variant="h6" component="h6">
        Bookings
      </Typography>
      <Divider />
      {open ? (
        <div>
          <FormControl component="fieldset" className={classes.formControl}>
            <InputLabel
              id="providerlabel"
              htmlFor="provider"
              className={classes.formControl}
            >
              Select Provider
            </InputLabel>
            <Select
              className={classes.formControl}
              labelId="provider"
              id="provider"
              value={provider}
              onChange={(event) => getEmployeesRole(event.target.value)}
              autoWidth
            >
              {providers &&
                providers.map((prov) => {
                  return (
                    <MenuItem key={prov.name} value={prov.name}>
                      {prov.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <InputLabel
              id="trainerlabel"
              htmlFor="trainer"
              className={classes.formControl}
            >
              {trainerLabel}
            </InputLabel>
            <Select
              className={classes.formControl}
              labelId="trainer"
              id="trainer"
              value={employee}
              onChange={(event) => setEmployee(event.target.value)}
              autoWidth
            >
              {employees &&
                employees.map((emp) => {
                  return (
                    <MenuItem key={emp.email_address} value={emp.email_address}>
                      {emp.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <TextField
              id="date"
              fullWidth
              label="Date of Appointment"
              type="date"
              value={appointmentDate}
              onChange={(event) => setAppointmentDate(event.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </FormControl>

          <FormControl component="fieldset" className={classes.formControl}>
            <TextField
              id="customeremail"
              label="Customer's Email"
              helperText="An email will be sent to the customer."
              type="email"
              fullWidth
              onChange={(event) => setCustomerEmail(event.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>

            <TextField
              id="customername"
              label="Customer's Name"
              fullWidth
              type="text"
              onChange={(event) => setCustomerName(event.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </FormControl>
          <FormControl>
            
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={saveAppointment}
            >
              Save
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={cancelAppointment}
            >
              Cancel
            </Button>
          </FormControl>
        </div>
      ) : (
        <div>
          <FormControl component="fieldset" className={classes.formControl}>
            <TextField
              id="date"
              fullWidth
              label="Select date of appointment"
              type="date"
              value={appointmentDate}
              onChange={(event) => getApps(event.target.value)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </FormControl>
          <BookingTable bookings={bookings} appointmentDate={appointmentDate} getApps={getApps}  />
        </div>
      )}
      <Fab
        aria-label={fab.label}
        className={fab.className}
        color={fab.color}
        onClick={handleClickOpen}
      >
        {fab.icon}
      </Fab>
    </div>
  );
}
