import React,{useEffect} from "react";
import { Box,Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FormControl } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { login } from '../Services/Services';
import { useAuth } from '../Contexts/AuthContext';
import { useHistory } from "react-router-dom";
import {BlockUI} from '../Backdrop/Backdrop';

const useStyles = makeStyles({
  
  card: {
    marginTop:50,
    maxWidth: 345
  },
  grid: {
    overflow:"auto",
    minHeight: "100vh",
    display:"flex"
  },
  formControl: {
    width:"100%"
  },
  error:{
    padding:10,
    backgroundColor:"red",
    color:"white",
  }
});

export default function Login() {
  const classes = useStyles();
  const [loginId, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  
  const { userObj,setUser } = useAuth();
  const [blockUI, setBlockUI] = React.useState(true);

  let history = useHistory();
  useEffect(() => {
    if(userObj !==undefined)
      history.replace("/");
    setBlockUI(false);
  },[userObj,history]);

  const loginButtonClick = () => {
    
    setError("");
    if(!loginId || !password){
      setError("Please enter valid credentials");
      return;
    }
      
    let auth = btoa(loginId+":"+password);

    setBlockUI(true);

    login(auth).then((resp)=>{

      setUser(resp);
      history.replace("/dashboard");
      setBlockUI(false);
    }).catch((error)=>{
      setUser(undefined);
      setError(error.message);
      setBlockUI(false);
    });
  }

  return (
    <Box>
      {
        blockUI ?
        <BlockUI />:""
      }
      <Grid container justifyContent="center"  spacing={0} >
        <Card className={classes.card}>
         
            
          <CardContent>
            <center><Typography gutterBottom variant="h5" component="h2">
                Appointment Bookings
            </Typography></center>
            
            <FormControl className={classes.formControl}>
        <InputLabel htmlFor="login">Login email</InputLabel>
        <Input fullWidth id="login" value={loginId} onChange={(e)=>setLogin(e.target.value)} />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input fullWidth type="password" id="password" value={password} onChange={(e)=>setPassword(e.target.value)} />
      </FormControl>
          </CardContent>
          {
            error ? 
            <FormControl className={classes.formControl}>
            <Typography variant="body2" className={classes.error}>{error}</Typography>
            </FormControl>
            :
            ""
          }
          <CardActions>
          
        <Button size="small" color="primary" onClick={()=>loginButtonClick()}>
          Login
        </Button>
        <Button size="small" color="primary" onClick={()=>setError("Invite only. Coming Soon!!")}>
          Sign Up
        </Button>
      </CardActions>
      <CardMedia
              component="img"
              alt="Kusol Appointment Bookings"
              height="250"
              image="https://kusol.co.nz/static/media/kusollogo.55adfafb.png"
              title="Kusol Appointment Bookings"
        />
        </Card>
      </Grid>
      </Box>
   
  );
}
