import React, { useEffect } from "react";
import { useAuth } from '../Contexts/AuthContext';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Header from "./Header/Header";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import InfoIcon from '@material-ui/icons/Info';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Home from "./Home/Home";
import Roles from "./Roles/Roles";
import Settings from "./Settings";
import Bookings from "./Bookings";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
  }));

const switchComponent = (param) => {

    switch(param){
      case "Home":
        return <Home />;
      case "Dashboard":
          return "Dashboard will be shown here";
      case "Roles":
          return <Roles />
      case "Bookings":
            return <Bookings />;
      case "Settings":
          return <Settings />;
      default:
        return "No Default Switch";
    }

  }
export default function Dashboard() {

    const { userObj,logOut } = useAuth();
    let history = useHistory();

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [route,setRoute] = React.useState("Home")

    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
      const handleDrawerClose = () => {
        setOpen(false);
      };

    useEffect(()=>{
        if(userObj === undefined){
            history.replace("/login");
        }
    },[userObj,history])
    
    
    return (
      <div className={classes.root}>
      <CssBaseline />
      <Header handleDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
          
          
          
        </div>
       
        
                            
                            
        <Divider />
        <Divider />
          <List>
          {/* <ListItem key={"dashboard"} button onClick={()=>setRoute("Dashboard")} > 
            <ListItemIcon><DashboardIcon fontSize="large" color="primary" /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem> */}
          <ListItem key={"roles"} button onClick={()=>setRoute("Roles")}> 
            <ListItemIcon><GroupAddIcon fontSize="large" color="primary" /></ListItemIcon>
            <ListItemText primary="Providers" />
          </ListItem>
          <ListItem key={"bookings"}  button onClick={()=>setRoute("Bookings")}> 
            <ListItemIcon><EventNoteOutlinedIcon fontSize="large" color="primary" /></ListItemIcon>
            <ListItemText primary="Bookings" />
          </ListItem>
          <ListItem key={"settings"}  button onClick={()=>setRoute("Settings")}> 
            <ListItemIcon><ScheduleOutlinedIcon fontSize="large" color="primary" /></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <Divider />
          <ListItem key={"aboutus"} button onClick={()=>setRoute("Aboutus")}> 
            <ListItemIcon><InfoIcon fontSize="large" color="primary" /></ListItemIcon>
            <ListItemText primary="About Kusol" />
          </ListItem>
          
          <ListItem key={"logout"} button onClick={()=>logOut()}> 
            <ListItemIcon><ExitToAppIcon fontSize="large" color="primary" /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
          </List>
        
        
      </Drawer>
      
      <main className={classes.content}>
        <div className={classes.toolbar} />
        
        {
          switchComponent(route)
        }
      </main>
      
    </div>
    )
}