import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Button} from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
export default function ConfirmDialog(props){

    const {message,id,deleteMethod,open,handleClose} = props;

    return(
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>handleClose("")}
        >
        <DialogTitle id="alert-dialog-slide-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose("")} color="primary">
            No
          </Button>
          <Button
            
            onClick={()=>{
                deleteMethod(id);
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )

}