import { Table, TableCell, TableRow,TableHead, TableBody, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ConfirmDialog from "./ConfirmDialogue";
import { useAuth } from "../Contexts/AuthContext";

import {
    deleteAppointment
  } from "../Services/Services";


export default function Bookings(props) {

    const { bookings } = props;
    const { appointmentDate,getApps } = props;
    const [deleteFlg,setDeleteFlg] = useState(false);
    const [bookingId,setBookingId] = useState();
    const { userObj, orgObj } = useAuth();
    
    const onDelete = (bookingId) => {
        setDeleteFlg(true);
        setBookingId(bookingId);

    }

    const handleClose = () => {
        setDeleteFlg(false);
        setBookingId();
    }

    const deleteBooking = () => {
        setDeleteFlg(false);
        deleteAppointment(userObj.token,orgObj.id,appointmentDate,bookingId).then(()=>{
            getApps(appointmentDate);
        });
        

        
    }
    return(
        <React.Fragment>

            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th">Customer Name</TableCell>
                            <TableCell component="th">Customer Email</TableCell>
                            <TableCell component="th">Provider</TableCell>
                            <TableCell component="th">Employee Name</TableCell>
                            <TableCell component="th">Employee Email</TableCell>
                            <TableCell component="th">Slot</TableCell>
                            <TableCell component="th">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            bookings && bookings.map(booking => {
                                return(
                                    <TableRow key={booking.id}>
                                        <TableCell>
                                            {booking.details.customer.name}
                                        </TableCell>
                                        <TableCell>
                                            {booking.details.customer.email}
                                        </TableCell>
                                        <TableCell>
                                            {booking.details.role}
                                        </TableCell>
                                        <TableCell>
                                            {booking.details.employee}
                                        </TableCell>
                                        <TableCell>
                                            {booking.details.employee}
                                        </TableCell>
                                        <TableCell>
                                            {booking.details.startTime}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton variant="" onClick={()=>onDelete(booking.id)} ><DeleteForeverIcon  fontSize="large" color="primary" /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })

                        }
                    </TableBody>
                </Table>
            </div>
            <ConfirmDialog  open={deleteFlg} id={bookingId} handleClose={handleClose} deleteMethod={deleteBooking} message={"Are you sure, you want to Delete the appointment?"}/>
        </React.Fragment>
    )
}