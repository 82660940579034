import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Divider, FormControl, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { useAuth } from "../Contexts/AuthContext";
import { BlockUI } from "../Backdrop/Backdrop";


import { createSettings ,getSettings} from "../Services/Services";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    margin: "10px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function range(
  start,
  stop,
  step = 1,
  circularFill = false,
  map = (value) => value
) {
  if (typeof stop === "undefined") {
    stop = start;
    start = 0;
  }

  if (step > 0 && start >= stop) {
    step = -step;
  }

  if (step < 0 && start <= stop) {
    return [];
  }

  let index = start;
  const result = [];

  if (circularFill) {
    const size = start + stop;
    for (index; step > 0 ? index < size : index > size; index += step) {
      result.push(map(index % stop));
    }
    return result;
  }

  for (index; step > 0 ? index < stop : index > stop; index += step) {
    result.push(map(index));
  }

  return result;
}

function times() {
  let rng = range(7, 21, 1);
  let slots = [];
  rng.forEach((r) => {
    slots.push({ slot: (r <= 9 ? "0" + r.toString() : r.toString()) + ":00" });
  });
  console.log(slots);
  return slots;
}

export default function Settings() {
  const classes = useStyles();
  const [settings, setSettings] = useState([]);
  const [slots, setSlots] = React.useState(times());

  const [startTime, setStartTime] = React.useState(slots[0].slot);
  const [endTime, setEndTime] = React.useState(slots[8].slot);
  const { userObj, orgObj } = useAuth();
  const [monday, setMonday] = React.useState(true);
  const [tuesday, setTuesday] = React.useState(true);
  const [wednesday, setWednesday] = React.useState(true);
  const [thursday, setThursday] = React.useState(true);
  const [friday, setFriday] = React.useState(true);
  const [saturday, setSaturday] = React.useState(false);
  const [sunday, setSunday] = React.useState(false);
  const [blockUi,setBlockUI] = React.useState(false);

  const addSetting = () => {
    let newSettings = [];

    newSettings.push({
      startTime: startTime,
      endTime: endTime,
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thursday: thursday,
      friday: friday,
      saturday: saturday,
      sunday: sunday,
    });
    setSettings(newSettings);
  };

  const saveSettings = () => {
    setBlockUI(true);
    let newSettings = {};

    newSettings = {
      startTime: startTime,
      endTime: endTime,
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thursday: thursday,
      friday: friday,
      saturday: saturday,
      sunday: sunday,
    };
    createSettings(userObj.token, orgObj.id, newSettings).then((resp)=> {
        setBlockUI(false);
    }).catch(err => console.log(err));
  };

  const getSetts=()=> {
      setBlockUI(true);
      getSettings(userObj.token, orgObj.id).then((settings)=>{
          const setting = settings[0];
          setMonday(setting.monday);
          setTuesday(setting.tuesday)
          setWednesday(setting.wednesday)
          setThursday(setting.thursday)
          setFriday(setting.friday)
          setSaturday(setting.saturday)
          setSunday(setting.sunday)
          setStartTime(setting.startTime)
          setEndTime(setting.endTime)
          setSettings(settings);
          setBlockUI(false);

      }).catch(error=>{
          console.log(error);
          setBlockUI(false);
      })
  }
  useEffect(()=>getSetts(),[])

  return (
    <div className={classes.root}>
        {
            blockUi ? <BlockUI />: ""
        }
      <Typography variant="h6" component="h6">
        Settings
      </Typography>
      <Divider />
      {settings.length > 0 ? (
        <div>
          <FormLabel component="legend" className={classes.formControl}>
            Working Hours
          </FormLabel>
          <FormControl component="fieldset" className={classes.formControl}>
            <InputLabel id="starttime" className={classes.formControl}>
              Start Time
            </InputLabel>
            <Select
              className={classes.formControl}
              labelId="startime"
              id="startime"
              value={startTime}
              onChange={(event) => setStartTime(event.target.value)}
              autoWidth
            >
              {slots &&
                slots.map((slot) => {
                  return (
                    <MenuItem key={slot.slot} value={slot.slot}>
                      {slot.slot}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl component="fieldset" className={classes.formControl}>
            <InputLabel id="endtime" className={classes.formControl}>
              End Time
            </InputLabel>
            <Select
              className={classes.formControl}
              labelId="endtime"
              id="endtime"
              value={endTime}
              onChange={(event) => setEndTime(event.target.value)}
              autoWidth
            >
              {slots &&
                slots.map((slot) => {
                  return (
                    <MenuItem key={slot.slot} value={slot.slot}>
                      {slot.slot}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormLabel component="legend" className={classes.formControl}>
            Working Days
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  checked={monday}
                  onChange={(event) => setMonday(event.target.checked)}
                  name="monday"
                />
              }
              label="Monday"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  checked={tuesday}
                  onChange={(event) => setTuesday(event.target.checked)}
                  name="tuesday"
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  checked={wednesday}
                  onChange={(event) => setWednesday(event.target.checked)}
                  name="wednesday"
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  checked={thursday}
                  onChange={(event) => setThursday(event.target.checked)}
                  name="thursday"
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  checked={friday}
                  onChange={(event) => setFriday(event.target.checked)}
                  name="friday"
                />
              }
              label="Friday"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  checked={saturday}
                  onChange={(event) => setSaturday(event.target.checked)}
                  name="saturday"
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch
                  checked={sunday}
                  onChange={(event) => setSunday(event.target.checked)}
                  name="sunday"
                />
              }
              label="Sunday"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={saveSettings}
          >
            <SaveIcon />
            Save Settings
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={addSetting}
        >
          <AddIcon />
          Add Settings
        </Button>
      )}
    </div>
  );
}
