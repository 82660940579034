import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      fontFamily: 'Lobster',
      fontSize:"42px"
    }
}));

export default function Home(){

    const classes = useStyles();

    return (
        <div className={classes.root}>Welcome to Kusol Bookings.<br /> Let's get started by creating some roles.</div>
    )
    
}