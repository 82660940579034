import React,{ createContext, useEffect} from "react";
import Cookie from "universal-cookie";

import jwt_decode from "jwt-decode";

export const AuthContext  = createContext();

export  function AuthContextProvider(props){
    const [ userObj, setUserObj] = React.useState(undefined);
    const [ orgObj, setOrgObj] = React.useState(undefined);
    
    useEffect(() => {
        let cookie = new Cookie();
        let access_token = cookie.get("access_token");
        
        if(access_token === undefined)
            setUserObj(undefined);
        else{
            setUserObj(getUserObj(access_token))
        };
      },[]);

    function logOut(){
        let cookie = new Cookie();
        cookie.remove("access_token");
        setUserObj(undefined);
    }
    
    function setCookie(idToken){
        let cookie = new Cookie();
        let date = new Date();
        date.setTime(date.getTime() * 60 * 60 * 24 * 1000);
        const options = { path: "/", expires: date}
        cookie.set("access_token",idToken,options);
    }
    function getUserObj(idToken){
        try{
            let decoded = jwt_decode(idToken);
            
            let uObj = {
                name:decoded.name,
                email:decoded.email,
                token:idToken
            }
            return uObj;
        }
        catch(err){
            return undefined
        }

    }
    function setUser (obj) {
        console.log(obj);
            if(obj !== undefined){
            let idToken = obj.idToken;
           
            setCookie(idToken);
            let uObj = getUserObj(idToken);
            setUserObj(uObj);
            }
    }

    return(
        <AuthContext.Provider value={{userObj,setUser,logOut,orgObj,setOrgObj}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export function useAuth(){
    const context = React.useContext(AuthContext);
    
    if (context === undefined) {
        
        throw new Error('useCount must be used within a AUTHCONTEXT Provider')
    }
    return context
}