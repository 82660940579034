import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  getRoles,
  createRoles,
  deleteRole,
} from "../../Services/Services";
import { useAuth } from "../../Contexts/AuthContext";
import { BlockUI } from "../../Backdrop/Backdrop";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Button, IconButton } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ConfirmDialog from "../ConfirmDialogue";
import Employee from "./Employee";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  formControl: {
    width: "100%",
  },
}));

export default function Roles() {
  const classes = useStyles();
  const [roles, setRoles] = React.useState([]);
  const { userObj, orgObj } = useAuth();

  const [rolename, setRoleName] = React.useState();
  const [blockUi, setBlockUi] = React.useState(false);

  const [providerId, setProviderId] = React.useState("");
  const [providerLabel, setProviderLabel] = React.useState("");

  const fab = {
    color: "primary",
    className: classes.fab,
    icon: <AddIcon />,
    label: "Add",
  };

  const [showEmployees, setShowEmployees] = React.useState(false);

  const [deleteFlg, setDeleteFlag] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const direction = "right";
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDelete = (rolename) => {
    setDeleteFlag(true);
    setRoleName(rolename);
  };

  const onRoleDrilldown = (rname) => {
    
    if (showEmployees && rname === rolename) {
      setShowEmployees(false);
      
      
    } else {
      setDeleteFlag(false);
      setRoleName(rname);
      setShowEmployees(true);
      // setBlockUi(true);
      
    }
  };

  const deleteRle = (rolename) => {
    setBlockUi(true);

    deleteRole(userObj.token, orgObj.id, rolename).then((success) => {
      getClientRoles();
      setRoleName("");
      setBlockUi(false);
      setDeleteFlag(false);
    });
  };
  const addRole = () => {
    setBlockUi(true);
    let newRoles = [];
    newRoles.push({
      name: providerId,
      label: providerLabel,
    });
    // setRoles(newRoles);
    setProviderLabel("");
    setProviderId("");
    createRoles(userObj.token, orgObj.id, newRoles).then((success) => {
      getClientRoles();
      setBlockUi(false);
      setOpen(false);
    });
  };

  function getClientRoles() {
    setBlockUi(true);
    getRoles(userObj.token, orgObj.id)
      .then((resp) => {
        console.log(resp);
        setRoles(resp);
        setBlockUi(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (userObj !== undefined) {
      getClientRoles();
    }
  }, [orgObj.id, userObj]);

  return (
    <div className={classes.root}>
      {blockUi ? <BlockUI /> : ""}

      <List>
        {roles &&
          roles.map((role) => {
            return (
              <React.Fragment key={"fg" + role.name}>
                <Slide
                  direction={direction}
                  in={true}
                  mountOnEnter
                  unmountOnExit
                >
                  <ListItem key={role.name} divider dense>
                    <ListItemText primary={role.name} secondary={role.label} />
                    <ListItemIcon>
                      <IconButton onClick={() => onDelete(role.name)}>
                        <DeleteForeverIcon fontSize="large" color="secondary" />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemIcon>
                      <IconButton onClick={() => onRoleDrilldown(role.name)}>
                        {showEmployees && role.name === rolename? (
                          <KeyboardArrowDownIcon
                            fontSize="large"
                            color="primary"
                          />
                        ) : (
                          <ChevronRightIcon fontSize="large" color="primary" />
                        )}
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                  </Slide>
                  {showEmployees && role.name === rolename ?
                    <Employee rolename={role.name}/>
                    :
                    ""
                  }
                
                <ConfirmDialog
                  key={"cd" + role.name}
                  open={role.name === rolename && deleteFlg}
                  id={role.name}
                  handleClose={setRoleName}
                  deleteMethod={deleteRle}
                  message={
                    "Deleting Role will also delete all Providers associated to it. Do you want to continue?"
                  }
                />
              </React.Fragment>
            );
          })}
      </List>

      <Fab
        aria-label={fab.label}
        className={fab.className}
        color={fab.color}
        onClick={handleClickOpen}
      >
        {fab.icon}
      </Fab>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Add Providers</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="providerId">Provider Name</InputLabel>
              <Input
                fullWidth
                id="providerId"
                value={providerId}
                onChange={(e) => setProviderId(e.target.value)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="providerlabel">Provider Label</InputLabel>
              <Input
                fullWidth
                id="providerlabel"
                value={providerLabel}
                onChange={(e) => setProviderLabel(e.target.value)}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!providerLabel || !setProviderId}
            onClick={addRole}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
